import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { logout } from '../../redux/actions/auth';
import { createNewMemberPlan, getMembers } from '../../redux/actions/members';

const MainWrapper = (props: any) => {
    const { children, auth, logout, setChangePw, changePw } = props;

    return (
        <div className='app-wrapper'>
            <header>
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <h3 className="title">
                                Penn Hills Passport
                            </h3>
                        </div>
                        <div className="col-6 text-right">
                            <button className="btn btn-danger btn-sm signout" onClick={() => logout()}>Sign Out</button>
                            {setChangePw &&
                              <>
                                <span>&nbsp;&nbsp;&nbsp;</span>
                              <button className="btn btn-warning btn-sm signout" onClick={() => setChangePw(!changePw)}
                                      style={{ marginRight: '12px' }}>Change Password
                              </button>
                              </>
                            }
                        </div>
                    </div>
                </div>

            </header>
            <div className="container">
                <div className="row">
                    <div className="col-2">
                    <nav className="main-nav">
                            <ul>
                                <li><Link to={'/users'}>Users</Link></li>
                                <li><Link to={'/news'}>News</Link></li>
                                <li><Link to={'/events'}>Events</Link></li>
                                <li><a href={'/directories/1'}>Shop Local</a></li>
                                <li><a href={'/directories/2'}>Communitiy</a></li>
                                {/*<li><Link to={'/careers'}>Careers</Link></li>*/}
                            </ul>
                        </nav>
                    </div>
                    <div className="col-10">
                        <main>{children}</main>
                    </div>
                </div>
            </div>

            <footer></footer>
        </div>
    );
}

const mapStateToProps = (state: any) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { logout })(MainWrapper);


